import { ScrambleTable } from "@cubing/scramble-table";

var socket;
type Scrambler = {
  tagId: string;
  name: string;
  display: number;
  isFetch: boolean;
  competitorTagId: string | null;
  competitorSolveNumber: string | null;
  groupId: string | null;
  round: string | null;
  eventId: string | null;
  attempt: string | null;
};

type Group = {
  eventId: string | null;
  groupId: string | null;
  round: string | null;
  attempt: string | null;
};

const createEmptyScrambler = (display: number) => {
  return {
    tagId: "",
    name: "",
    display: display,
    isFetch: true,
    competitorTagId: null,
    competitorSolveNumber: null,
    groupId: null,
    round: null,
    eventId: null,
    attempt: null,
  };
};

var competitionId;

class App {
  tagFunctionPromiseResolver: ((value: string) => void) | null = null;

  tagListenerAssignment: Promise<string>;
  scramblers: Scrambler[];
  scrambleGroups: Group[];
  selectedGroup: Group;
  scramblerStationSet: number;
  scrambleTable = document.body.appendChild(
    new ScrambleTable({
      setScramblerCallback: (displayNumber) =>
        this.setScramblerCallback(displayNumber),
    })
  );

  clearByScrambler(displayNumber: number) {
    this.scramblers[displayNumber].competitorSolveNumber = null;
    this.scramblers[displayNumber].competitorTagId = null;
    this.scramblers[displayNumber].isFetch = true;
    this.scramblers[displayNumber].groupId = null;
    this.scramblers[displayNumber].round = null;
    this.scramblers[displayNumber].eventId = null;
    this.scramblers[displayNumber].attempt = null;
  }

  updateScramblerName(displayNumber: number, newName: string) {
    const clearButton = document.querySelector(
      `button[data-display-number="${displayNumber}"]`
    );
    if (clearButton instanceof HTMLButtonElement) {
      clearButton.textContent = `Clear scramble for ${newName}`;
    }
  }

  createDropdown() {
    const dropdown = document.createElement("select");
    dropdown.addEventListener("change", (event) => {
      const selectedIndex = (event.target as HTMLSelectElement).selectedIndex;
      this.selectedGroup = this.scrambleGroups[selectedIndex];
      // Handle the selected group as needed
    });
    console.log(this.scrambleGroups);
    if (this.scrambleGroups !== null && this.scrambleGroups !== undefined) {
      this.scrambleGroups.forEach((group, index) => {
        const option = document.createElement("option");
        option.value = index.toString();
        option.text = `Event: ${group.eventId}, Round: ${group.round}, Group: ${group.groupId}, Attempt: ${group.attempt}`;
        dropdown.add(option);
      });
      document.body.prepend(dropdown);
    }
  }

  matchAny(tagId: string) {
    return this.scramblers.some((scr) => scr.tagId === tagId);
  }
  constructor() {
    this.createDropdown();
    this.scramblers = [createEmptyScrambler(0), createEmptyScrambler(1)];

    this.scramblerStationSet = -1;
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const code = url.searchParams.get("code");
    if (code) {
      this.login(code);
    } else {
      console.error("Authorization Code not found in the URL");
    }
    let signedInAs = null;
    this.scrambleTable.addEventListener(
      "scramble-cleared",
      (e: CustomEvent<{ displayIndex: number }>) => {
        this.clearByScrambler(e.detail.displayIndex);
      }
    );
    const jwt = this.getCookie("jwt");

    if (jwt) {
      signedInAs = "TimeBase";
    }

    document.addEventListener("TB_tag-scanned", (e: any) => {
      console.log("Got tag id: " + e.detail.tag_id);
      this.onTagId(e.detail.tag_id, e.detail.reader);
    });

    if (signedInAs) {
      const signOutButton = document.createElement("button");
      signOutButton.textContent =
        "Sign out of Delegate account (do not click unless scrambles do not load)";
      signOutButton.addEventListener("click", this.signOut.bind(this));
      this.scrambleTable.customHeaderElement().textContent = `Signed in as ${signedInAs}!`;
      this.scrambleTable.customHeaderElement().appendChild(signOutButton);
    } else {
      const signInButton = document.createElement("button");
      signInButton.textContent = "Sign in with the WCA";
      signInButton.addEventListener("click", this.signInWithWCA.bind(this));
      this.scrambleTable.customHeaderElement().appendChild(signInButton);
    }
    const NFC = document.createElement("button");
    NFC.textContent = "Web NFC";
    this.scrambleTable.customHeaderElement().prepend(NFC);
    NFC.addEventListener("click", async () => {
      NFC.textContent = "Scanning…";
      // From https://googlechrome.github.io/samples/web-nfc/

      console.log("User clicked scan button");

      try {
        const ndef = new (globalThis as any).NDEFReader();
        await ndef.scan();
        console.log("> Scan started");

        ndef.addEventListener("readingerror", () => {
          console.log(
            "Argh! Cannot read data from the NFC tag. Try another one?"
          );
        });

        ndef.addEventListener("reading", ({ message, serialNumber }) => {
          this.onTagId(serialNumber.replaceAll(":", "").toUpperCase(), 0);
        });
      } catch (error) {
        console.log("Argh! " + error);
      }
    });
  }

  async checkAndPromptForRoundSwitch(
    currentRound: string,
    currentEventId: string,
    currentGroupId: string,
    currentAttempt: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      var matched = false;
      console.log(this.scrambleGroups);
      for (let i = 0; i < this.scrambleGroups.length; i++) {
        if (
          currentRound !== this.scrambleGroups[i].round ||
          currentEventId !== this.scrambleGroups[i].eventId ||
          currentGroupId !== this.scrambleGroups[i].groupId ||
          currentAttempt !== this.scrambleGroups[i].attempt
        ) {
        } else {
          matched = true;
          resolve(true);
        }
      }
      if (!matched) {
        const shouldSwitch = window.confirm(
          `The current round (${currentEventId} Round ${currentRound} Group ${currentGroupId}${
            currentAttempt !== "0" ? ` Attempt ${currentAttempt}` : ""
          }) is different from the open scramble sets. If you want to switch the competitor to the current round and group, please have the delegate unmap ${currentEventId} Round ${currentRound}`
        );
        resolve(shouldSwitch);
      }
    });
  }

  async login(code: string) {
    // const redirectUri = "http://localhost:1234/"; //local
    const redirectUri = "https://scramble.timebase.live"; // prod
    const queryParams = new URLSearchParams();
    queryParams.set("token", code);
    queryParams.set("redirect_uri", redirectUri);

    const apiUrl = `https://api.timebase.live/users/login?${queryParams.toString()}`;

    let jwt = (await (await fetch(apiUrl, { method: "POST" })).json()).jwt;
    document.cookie = `jwt=${jwt}`;
  }

  getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  async promptForNumberOfCubesAsync(): Promise<number | null> {
    const userInput = window.prompt("How many cubes will they be attempting?");

    if (userInput === null || userInput.trim() === "") {
      return null;
    }

    const numberOfCubes = parseInt(userInput, 10);

    if (isNaN(numberOfCubes)) {
      return null;
    }

    return numberOfCubes;
  }

  async setScramblerCallback(displayNumber: number): Promise<string> {
    this.scramblers[displayNumber] = {
      tagId: "",
      name: "",
      display: 0,
      isFetch: true,
      competitorSolveNumber: null,
      competitorTagId: null,
      groupId: null,
      round: null,
      eventId: null,
      attempt: null,
    };
    this.scramblerStationSet = displayNumber;
    await new Promise((resolve) => setTimeout(resolve, 5000));
    this.scramblerStationSet = -1;
    if (this.scramblers[displayNumber].tagId !== "") {
      const jwt = this.getCookie("jwt");
      const response = await fetch(
        "https://api.timebase.live/name-from-tag?token=" +
          jwt +
          "&tag_id=" +
          this.scramblers[displayNumber].tagId
      );

      if (response.status != 200) {
        console.error(`Server responded with: ${response.body}`);
        return;
      }

      const scrambler = await response.json();

      this.scramblers[displayNumber].name = scrambler.name;

      this.updateScramblerName(displayNumber, scrambler.name);

      return scrambler.name;
    }
    return "";
  }
  async signInWithWCA() {
    window.location.href =
      "https://www.worldcubeassociation.org/oauth/authorize?client_id=yrJNHmXnJQn7E4v2z1vDsOTADJjIQj4733_QZhAJc9k&redirect_uri=https%3A%2F%2Fscramble.timebase.live&response_type=code&scope=manage_competitions+email+dob+public"; // Prod
    // "https://www.worldcubeassociation.org/oauth/authorize?client_id=yrJNHmXnJQn7E4v2z1vDsOTADJjIQj4733_QZhAJc9k&redirect_uri=http%3A%2F%2Flocalhost%3A1234%2F&response_type=code&scope=manage_competitions+email+dob+public"; // testing locally
  }

  async signOut() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location.reload();
  }
  async getCurrentOpenScrambleSets(tag_id) {
    const jwt = this.getCookie("jwt");

    const response = await fetch(
      "https://api.timebase.live/current-scramble-sets?token=" +
        jwt +
        "&tag_id=" +
        tag_id
    );

    if (response.status != 200) {
      console.error(`Server responded with: ${response.body}`);
      return;
    }
    const group_data = await response.json();
    this.scrambleGroups = [];
    group_data.forEach(
      (g: { event_id: any; round: any; attempt: any; group_id: any }) => {
        this.scrambleGroups.push({
          eventId: g.event_id,
          round: g.round,
          attempt: g.attempt,
          groupId: g.group_id,
        });
      }
    );
    if (this.scrambleGroups.length === 1) {
      this.selectedGroup = this.scrambleGroups[0];
    }
  }

  async onTagId(tag_id: string, reader: number) {
    // document.body.prepend(tag_id); // I think we don't need this anymore.  TODO remove?
    if (tag_id == null || !(reader == 0 || reader == 1)) return;

    await this.getCurrentOpenScrambleSets(tag_id);

    const dropdown = document.querySelector("select");

    if (dropdown !== null) dropdown.innerHTML = ""; // Clear existing options
    if (dropdown === null) {
      this.createDropdown();
    } else {
      this.scrambleGroups.forEach((group, index) => {
        const option = document.createElement("option");
        option.value = index.toString();
        option.text = `Event: ${group.eventId}, Round: ${group.round}, Group: ${group.groupId}, Attempt: ${group.attempt}`;

        dropdown.add(option);
      });
    }

    if (reader === this.scramblerStationSet) {
      this.scramblers[reader].tagId = tag_id;
      return;
    }

    const jwt = this.getCookie("jwt");
    if (this.scramblers[reader].isFetch && !this.matchAny(tag_id)) {
      if (this.selectedGroup === undefined) {
        this.selectedGroup = this.scrambleGroups[0];
      }
      const response = await fetch(
        "https://api.timebase.live/current-scramble?token=" +
          jwt +
          "&competitor_id=" +
          tag_id +
          "&event_id=" +
          this.selectedGroup.eventId +
          "&round=" +
          this.selectedGroup.round +
          "&group_id=" +
          this.selectedGroup.groupId +
          "&attempt=" +
          this.selectedGroup.attempt
      );

      if (response.status != 200) {
        console.error(`Server responded with: ${response.body}`);
        var error = (await response.json()).error;
        if (error != null) {
          alert(error);
        } else {
          alert("Unknown error from server");
        }
        return;
      }

      const scramble_data = await response.json();

      const shouldSwitch = await this.checkAndPromptForRoundSwitch(
        scramble_data.round,
        scramble_data.event,
        scramble_data.group,
        scramble_data.attempt
      );

      if (
        competitionId == null ||
        competitionId != scramble_data.competition_id
      ) {
        competitionId = scramble_data.competition_id;
        socket = new WebSocket(
          "wss://api.timebase.live/scramble-swap/" + competitionId
        );
        socket.addEventListener("message", (event) => {
          this.handleWebSocketMessage(event);
        });

        // Event listener for WebSocket connection open
        socket.addEventListener("open", () => {
          console.log("WebSocket connection opened.");
        });

        // Event listener for WebSocket errors
        socket.addEventListener("error", (error) => {
          console.error("WebSocket error:", error);
        });

        // Event listener for WebSocket connection close
        socket.addEventListener("close", () => {
          console.log("WebSocket connection closed.");
          competitionId = null;
        });
      }

      // if (shouldSwitch) {
      //   scramble_data.round = scramble_data.current_open_set.round;
      //   scramble_data.event = scramble_data.current_open_set.event_id;
      //   scramble_data.group = scramble_data.current_open_set.group_id;
      //   scramble_data.attempt = scramble_data.current_open_set.attempt;
      // }
      this.scramblers[reader].competitorSolveNumber =
        scramble_data.scramble_number;
      this.scramblers[reader].competitorTagId = tag_id;
      this.scramblers[reader].display = reader;
      this.scramblers[reader].isFetch = false;
      this.scramblers[reader].groupId = scramble_data.group;
      this.scramblers[reader].round = scramble_data.round;
      this.scramblers[reader].eventId = scramble_data.event;
      this.scramblers[reader].attempt = scramble_data.attempt;
      var numCubes = null;

      if (scramble_data.event === "333mbf") {
        //Create alert with popup for number attempted
        numCubes = await this.promptForNumberOfCubesAsync();
      }

      this.scrambleTable.displays[reader].setScramble({
        competitorName: scramble_data.competitor_name,
        competitorCompetitionID: scramble_data.competitor_id,
        eventID: scramble_data.event,
        roundNumber: scramble_data.round,
        scrambleSetNumber: scramble_data.group,
        attemptID: scramble_data.scramble_number,
        passcode: scramble_data.scramble_password,
        numSubScrambles: numCubes,
      });
    } else if (
      this.scramblers[reader].tagId === tag_id &&
      this.scramblers[reader].competitorTagId !== null
    ) {
      this.signScramble(
        reader,
        this.scramblers[reader].competitorTagId,
        tag_id,
        this.scramblers[reader].competitorSolveNumber,
        this.scramblers[reader].round,
        this.scramblers[reader].groupId,
        this.scramblers[reader].eventId,
        this.scramblers[reader].attempt
      );
    }
  }

  handleWebSocketMessage(event) {
    const messageData = JSON.parse(event.data);
    window.alert("scramble set changed!");
  }

  async signScramble(
    displayNumber: number,
    competitor_tag_id: string,
    scrambler_tag_id: string,
    solve_number: string, // This is a string because Extras are represented as "E1" or "E2".
    round: string,
    group_id: string,
    event_id: string,
    attempt: string
  ) {
    const jwt = this.getCookie("jwt");

    // TODO: Max: add NFC logic
    const response = await fetch(
      "https://api.timebase.live/sign-scramble?token=" + jwt,
      {
        method: "POST",
        body: JSON.stringify({
          competitor_id: competitor_tag_id,
          scrambler_id: scrambler_tag_id,
          solve: solve_number,
          round: round,
          group_id: group_id,
          event_id: event_id,
          attempt: attempt,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      this.scrambleTable.displays[displayNumber].markAsSigned();
      this.scramblers[displayNumber].isFetch = true;
    } else {
      alert("your scramble didn't sign properly");
    }
  }
}

let app = new App();
// Make the app object available in the console for debugging.
// Try running: app.updateScramble()
globalThis.app = app;

// app.scrambleTable.setEncryptedScrambleJSONForDebugging(
//   // Note: this is fake data, a lot of fields are missing compared to a real file.
//   {
//     competitionName: "Fake Test Competition",
//     wcif: {
//       events: [
//         {
//           id: "333",
//           rounds: [
//             {
//               scrambleSetCount: 8,
//               scrambleSets: [
//                 {
//                   id: 1,
//                   ciphertext:
//                     "01e1e3c64fd930c1649b5ce8a3f627f4789bd621bf2148e5d3a916cbed4477c66c590efd42331f642539f4fd061b4c210b1557ea4a9bcc4ea509b2842b9c197fd7190df8f42ef5a2d55067c923997d89ada2943c316f6a0dff9df9371bdebd5823c424f2745b3cd26458390c4ee9add4e3148af8f433a8e7d006f446a9e9872d743a22f918c45de2cfd49aae2ca1784e832ba8d84f143a03c33b083bded8a2f4c0f92388e1e48a4f2fa3478b1f87cd23e8886b7cad15093612d4c645e9e160379c16da9c251fb2480ae08744cc11a380190ae2e9caa371df1ffd89aff75fa9b6e29c3ba54278c7c0b6481cb84295270dc0c75effdb9092f43ae217a7fd3c47438d8b0c7d1121c253c75fe4695f7d5241f53401a33ffd1f292c660522910727f44bae97dd4787fb911e45e0c3dea65c5b9be3a90d571a1dbe0bb998cd7c0589b014f4f96a799e75684b81c8133f890f3d8ed75cde18c6c89d06e9c2d5fbbde1f3ff2fdabafcfed2a8ac1b3a021d6ca0b92c1df9ab76f33e7a349bec7f6c02d8b0d7bc5350e0e6523f3548fbc5f8f8cb1625bdc5d42bbdca87ef914489f8a2b74eabbfcdbcaf576481d554ddb22926aa81719f70f481996d159a8289b8da94a1e84d376bdae2397eaca239d40e4603054abcae2f62289fd27a66b2c0ab",
//                 },
//                 {
//                   id: 2,
//                   ciphertext:
//                     "01c97c010f6b40eabafedbaec705d0dd779dd9004fe8f71c7e0e6d7ad7c82e157aa4f6e7465b485d17a7568439024e6f9c6996a026e94bd4f12140e715b0f3edad69f9f5411bf8547ceeb018bd9bf0ce8ba27a409ff6a6506904804aa54395e273aa4ba992e9f012be49ebe003e44759ba4686f737db83936d63ad184bc3110378b6bd97dd455f7ef82c04e32881206beb2853373830a036dac01eff8858197b0b7e04639bcc132c80519d140604238c115071d5b3dcf4a5801fd59dbb5860088797407fdf8eb294b495776944dfca747bc72a301df813854c8e80266685dd6365ce4a3e75066984b2269534754f4728dad1e7aaef56fbc2f44b0a5d06b48ff47f352e9b66372be53860697fcfc1cd676b68c0a3abbbd888a6657627279e7028ef1116d0c4f568d43eb4b60216460daaea973c30bbeb5faa464d329f68a0110bcc8b95099a71c66adb4814229f736223ba7244f3c52c28e9c85b8274294a95817ff11c1dea727783e41d06aa7d8b8896cb348c60ee2d3a959b513ccc8041167ab356922f9bf2cc95f2529843f9ecdd7fd29128a7445c7d0a8a455b3d44f5c2d6d9b348869361872939eb2ac82e04f1892be93a8f9b57f0a6777a25e786faed02cda04e686d27ddae2a4ead4b855ce1a376822ba1a40b",
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//     encryptedScrambles: true,
//   }
// );

// app.onTagId("8CD5E837", 0);
// app.onTagId("B0928616", 1);
